<template>
    <div class="content-page">
        <div class="content-nav">
            <el-breadcrumb class="breadcrumb" separator="/">
                <el-breadcrumb-item :to="{ name: 'discount' }">折扣管理</el-breadcrumb-item>
                <el-breadcrumb-item>{{infoForm._id ? '编辑折扣' : '添加折扣'}}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="operation-nav">
                <el-button type="primary" @click="onSubmitInfo">确定保存</el-button>
                <el-button @click="goBackPage" icon="arrow-left">返回列表</el-button>
            </div>
        </div>
        <div class="content-main">
            <div class="form-table-box">
                <el-form ref="infoForm" :rules="infoRules" :model="infoForm" label-width="120px">
                    
                    <el-form-item label="折扣活动" prop="discount_name">
                        <el-input  v-model="infoForm.discount_name"></el-input>
                    </el-form-item>

                    <el-form-item label="关联商品" prop="goods_name">
                        <el-autocomplete
                        class="inline-input"
                        v-model="infoForm.goods_name"
                        :fetch-suggestions="goodsQuerySearch"
                        @select="handleSelect"
                        placeholder="请输入内容">
                        </el-autocomplete>
                    </el-form-item>
                    
                    <el-form-item label="选择型号" prop="spec_name">
                        <el-select v-model="infoForm.spec_id" @change="selectSpecChanged" placeholder="请选择">
                            <el-option
                              v-for="item in specDatas"
                              :key="item._id"
                              :label="item.spec_name"
                              :value="item._id">
                            </el-option>
                          </el-select>
                    </el-form-item>

                    <el-form-item label="原价" prop="retail_price">
                        <el-input
                            v-model="infoForm.retail_price"
                            :disabled="true">
                            </el-input>
                    </el-form-item>

                    <el-form-item label="折扣价" prop="actual_price" >
                        <el-input  v-model="infoForm.actual_price" @change="changeActualPrice"></el-input>
                    </el-form-item>
                    
                    <el-form-item label="折扣" prop="discount" >
                        <el-input   readonly="readonly" v-model="infoForm.discount" @change="changeDiscount"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="onSubmitInfo" >确定保存</el-button>
                        <el-button @click="goBackPage">返回列表</el-button>
                    </el-form-item>

                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '@/config/api';
    import $ from 'jquery'
    export default {
        data() {
            return {
                root: '',
                qiniuZone:'',
                activeName:"3",
                picData: {
                    token: ''
                },
                preGoods_id:'',
                preSpec_id:'',
                uploaderHeader: {
                    'LuoShao-Token': localStorage.getItem('token') || '',
                },
                infoForm: {
                    discount_name:'',
                    goods_id:'',
                    goods_name:'',
                    spec_id:'',
                    spec_name:'',
                    discount:'',
                    actual_price:'',
                    retail_price:''
                },
                specDatas:[],
                infoRules: {
                    discount_name: [
                        {required: true, message: '请填写促销活动名称', trigger: 'blur'},
                    ],
                    goods_name: [
                        {required: true, message: '请填写商品名称', trigger: 'blur'}
                    ],
                    spec_name: [
                        {required: true, message: '请选择商品型号', trigger: 'blur'},
                    ]
                },
                goodsNameTipsArray:[],//用于存放下拉提醒框中数据的数组
		        goodsNameArray:[],//页面初始化时全部数据
            }
        },
        watch:{
            'infoForm.goods_name':{
            handler: function() {
                    this.goodsNameTipsArray = [];//这是定义好的用于存放下拉提醒框中数据的数组
                    var len = this.goodsNameArray.length;//nameArray是页面初始化时全部数据
                    var arr = [];
                    for (var i = 0; i < len; i++) {//根据输入框中inputName的值进行模糊匹配
                        if (this.goodsNameArray[i].name.indexOf(this.infoForm.goods_name) >= 0) {
                            arr.push(this.goodsNameArray[i]);//符合条件的值都放入arr中
                        }
                    }

                    //el-autocomplete元素要求数组内是对象，且有value属性，此处做一个格式调整
                    for (var i = 0; i < arr.length; i++) {
                        var obj = { value: "" };
                        obj.value = arr[i].name;
                        obj.id = arr[i]._id;
                        obj.retail_price = arr[i].retail_price;
                        this.goodsNameTipsArray.push(obj);
                    }
                    
                }
            },
        },
        methods: {
            changeActualPrice(v)
            {
                if(!!!this.infoForm.goods_name)
                {
                    this.infoForm.actual_price = '';
                    this.$message.error('请先选择商品！');
                    return;
                }

                if(parseFloat (this.infoForm.retail_price) <parseFloat(this.infoForm.actual_price))
                {
                    this.$message.error('折扣价格不能大于原价哦！');
                    this.infoForm.actual_price = '';
                    return;
                }
                else if(this.infoForm.actual_price<=0)
                {
                    this.infoForm.actual_price = 0;
                }
                var discount = this.infoForm.actual_price/this.infoForm.retail_price;
                //discount = discount.toFixed(2);
                this.infoForm.discount =(discount*10).toFixed(2) ;
            },
            selectSpecChanged(v)
            {
                for (const elem of this.specDatas) {
                    if(elem._id == v)
                    {
                        this.infoForm.spec_name = elem.spec_name;
                        this.infoForm.retail_price = elem.retail_price;

                        if(!!this.preSpec_id)
                        {
                            if(this.preSpec_id != this.infoForm.spec_id)
                            {
                                this.infoForm.actual_price = '';
                                this.infoForm.discount = '';
                            }
                        }

                        this.preSpec_id = this.infoForm.spec_id;
                        return;
                    }
                }
            },
            changeDiscount(v)
            {
                console.log("changeDiscount is " );
                console.log(v);
            },
            handleSelect(item) {
                //console.log("item item item item item item s");
                

                this.infoForm.goods_name = item.value;
                this.infoForm.goods_id = item.id;


                if(!!this.preGoods_id)
                {
                    if(this.preGoods_id != this.infoForm.goods_id)
                    {
                        this.infoForm.actual_price = '';
                        this.infoForm.retail_price = '';
                        this.infoForm.discount = '';
                        this.infoForm.spec_id = '';
                        this.preGoods_id = this.infoForm.goods_id;
                        this.getTargetSpecData();//更新下拉菜单
                    }
                }
                else
                {
                    this.preGoods_id = this.infoForm.goods_id;
                    this.getTargetSpecData();//更新下拉菜单
                }

                //do something
            },
            getTargetSpecData()
            {
                var that = this;
                this.axios.get('goods/getGoodsSpec',
                    {
                        params: {
                            goods_id:this.preGoods_id
                        }
                    }
                ).then((response) => {
                    console.log("target Spec Data is ");
                    console.log(response.data);
                    if(response.data.code>0)
                    {
                        that.specDatas = response.data.data;//获取商品型号
                    }
                })
            },
            goodsQuerySearch(queruString, cb){
				var nameTipsArray = this.goodsNameTipsArray;
				cb(nameTipsArray);
		    },
            getQiniuToken() {
                let that = this
                this.axios.get('qiniu/getQiniuToken').then((response) => {
                    let resInfo = response.data.data;
                    //console.log(resInfo.token);
                    that.picData.token = resInfo.token;
                    that.url = resInfo.url;
                })
            },
            goBackPage() {
                this.$router.go(-1);
            },
            getDiscountInfo()
            {
                if(!this.infoForm.discount && !this.infoForm.actual_price)
                {
                    this.$message.error('折扣或者促销金额不能为空');
                    return -3;
                }
            },
            getGoods()
            {
                this.axios.get('discount/getGoods').then((response) => {
                    if(response.data.code>0)
                    {
                        console.log(response.data);   
                        this.goodsNameArray = response.data.data;
                    }
                })
            },
            getTargetGoodsInfo()
            {
                var that = this;
                this.axios.get('discount/getTargetGoodsInfo',
                {
                    params: {
                        id: that.infoForm.goods_id,
                        spec_id: that.infoForm.spec_id,
                    }
                }).then((response) => {
                    if(response.data.code>0)
                    {
                        console.log("response.data");
                        console.log(response.data);
                        that.infoForm.retail_price = response.data.data.retail_price;
                        that.specDatas = response.data.specDatas;
                    }
                });
            },
            onSubmitInfo() {
                this.$refs['infoForm'].validate((valid) => {
                    if (valid) {
                        var result = this.getDiscountInfo();//根据用户选择的类型 和输入，获取一个通用名称。便于列表展示
                        if(result<0)
                        {
                            return;
                        }
                        var binfo ={
                            discount_name:this.infoForm.discount_name,
                            actual_price:this.infoForm.actual_price,
                            discount:this.infoForm.discount,
                            goods_id:this.infoForm.goods_id,
                            goods_name:this.infoForm.goods_name,

                            spec_id:this.infoForm.spec_id,
                            spec_name:this.infoForm.spec_name,
                        }

                        if(!!this.infoForm._id)
                        {
                            this.axios.post('discount/udpateDiscount',
                            {
                                discountinfo: binfo,
                                id:this.infoForm._id
                            }).then((response) => {
                                console.log("保存结果出来了！！！");
                                console.log(response);
                                if (response.data.code> 0) {
                                    this.$message({
                                        type: 'success',
                                        message: '更新成功'
                                    });
                                    this.$router.go(-1);
                                }  else {
                                    if(response.data.code==-2)
                                    {
                                        this.$message({
                                            type: 'error',
                                            message: '折扣商品已存在！'
                                        });
                                    }
                                    else
                                    {
                                        this.$message({
                                            type: 'error',
                                            message: '更新折扣失败'
                                        });
                                    }
                                }
                        })
                        }
                        else
                        {
                            this.axios.post('discount/addDiscount',
                            {
                                discountinfo: binfo
                            }).then((response) => {
                                console.log("保存结果出来了！！！");
                                console.log(response);
                            if (response.data.code> 0) {
                                this.$message({
                                    type: 'success',
                                    message: '关联成功'
                                });
                                this.$router.go(-1);
                            } else {
                                if(response.data.code==-2)
                                {
                                    this.$message({
                                        type: 'error',
                                        message: '折扣商品已存在！'
                                    });
                                }
                                else
                                {
                                    this.$message({
                                        type: 'error',
                                        message: '设置折扣失败'
                                    });
                                }
                            }
                        })
                        }
                        
                    } else {
                        return false;
                    }
                });
            },
        },
        components: {
        },
        computed: {
        },
        mounted() {
            if(!!this.$route.query.discountinfo)
            {
                var discountinfo = this.$route.query.discountinfo ;
                this.infoForm._id = discountinfo._id;
                this.infoForm.discount_name = discountinfo.discount_name;
                this.infoForm.discount = discountinfo.discount;
                this.infoForm.goods_id = discountinfo.goods_id;
                this.infoForm.goods_name = discountinfo.goods_name;
                
                this.infoForm.spec_id = discountinfo.spec_id;
                this.infoForm.spec_name = discountinfo.spec_name;

                this.infoForm.actual_price = discountinfo.actual_price;
                this.infoForm.retail_price = '';

                this.preGoods_id = this.infoForm.goods_id;
                this.preSpec_id = this.infoForm.spec_id;
            }
            
            this.getGoods();
            this.getQiniuToken();

            if(!!this.infoForm.goods_id)
            {
                this.getTargetGoodsInfo();
            }
            this.root = api.rootUrl;
            this.qiniuZone = api.qiniu;

        },
    }

</script>

<style scoped>
    /* .edit_container{ */
    /*.avatar-uploader .el-upload {*/
    /*display: none;*/
    /*}*/

    .close-i{
		position: absolute;
		top:-8px;
		right: -9px;
		display: none;
		cursor: pointer;
		font-size: 18px;
		background-color: white;
		border-radius: 10px;
	}
	.newtag{
		position: relative;
		cursor: text;
		overflow: visible;
		display: inline-block;
		padding: 0;	
	}
    .addtag
    {
        margin-left: 10px;
    }
	.newtag-content{
		margin: 7px 15px; 
	}
    .video-wrap{
        width: 300px;
    }
    .dialog-header {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
    }

    .dialog-header .value {
        width: 150px;
        margin-right: 14px;
    }

    .input-wrap .el-input {
        width: 200px;
        float: left;
        margin: 0 20px 20px 0;
    }

    .input-wrap .el-input input {
        background-color: #fff !important;
        color: #233445 !important;
    }

    .specFormDialig .el-input {
        width: 150px;
        margin-right: 10px;
    }

    .el-select-class {
        width: 200px;
        margin-right: 20px;
    }

    .sepc-form {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
    }

    .spec-form-wrap {
        margin-top: 0 !important;
    }

    .add-spec {
        margin-top: 10px;
    }

    .spec-form-wrap .header {
        display: flex;
        justify-content: flex-start;
    }

    .spec-form-wrap .header .l {
        width: 200px;
        margin-right: 20px;
    }

    .spec-form-wrap .header .m {
        width: 200px;
        margin-right: 20px;
    }

    .spec-form-wrap .header .m {
        width: 200px;
        margin-right: 20px;
    }

    /*.sepc-form div{*/
    /*margin-left: 0;*/
    /*}*/

    .float-right {
        float: right;
    }

    .sepc-form .el-input {
        width: 200px;
        margin-right: 20px;
    }

    .marginTop20 {
        margin-top: 20px;
    }

    .checkbox-wrap .checkbox-list {
        float: left;
        margin-right: 20px;
    }

    .upload_ad{
        display: none;
    }
    .upload_ad .el-upload--picture-card {
        display: none;
    }

    .ql-container {
        min-height: 200px;
        max-height: 400px;
        overflow-y: auto;
    }

    .image-uploader-diy {
        /*height: 200px;*/
        position: relative;
    }

    /*.dele-list-pic {*/
        /*position: absolute;*/
        /*left: 380px;*/
        /*top: 0px;*/
    /*}*/

    .image-uploader-diy .el-upload {
        border: 1px solid #d9d9d9;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .image-uploader-diy .el-upload:hover {
        border-color: #20a0ff;
    }

    .image-uploader-diy .image-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 200px;
        height: 200px;
        line-height: 200px;
        text-align: center;
    }

    .image-uploader-diy .image-show {
        min-width: 105px;
        height: 105px;
        display: block;
    }

    .image-uploader-diy .new-image-uploader {
        font-size: 28px;
        color: #8c939d;
        width: 165px;
        height: 105px;
        line-height: 105px;
        text-align: center;
    }

    .image-uploader-diy .new-image-uploader .image-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 165px;
        height: 105px;
        line-height: 105px;
        text-align: center;
    }

    .image-uploader-diy .new-image-uploader .image-show {
        width: 165px;
        height: 105px;
        display: block;
    }

    .item-url-image-fuzhu .el-input {
        width: 260px;
    }

    .hidden {
        display: none;
    }
</style>
